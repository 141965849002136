$(document).on('ready turbolinks:load', function() {
  function initializeAutocomplete(id) {
     var element = document.getElementById(id);
     console.log(element);
     if (element) {
       var autocomplete = new google.maps.places.Autocomplete(element, { types: ['geocode']});
      }
  }


  google.maps.event.addDomListener(window, 'load', function() {
    initializeAutocomplete('home_page_location_near');
  });
});
